<template>
  <div>
    <div class="post-head">
      <img src="@/assets/picture/pic-0122.png" alt="picture" />
      
        <h1 class="post-head-title">{{ postData.title }}</h1>
      
    </div>
    <div class="post site__centered">
      <div class="post-body">
        <h3 class="post-body-title">{{ postData.title }}</h3>
        <p class="post-body-date">{{ postData.posted_at | formatDate }}</p>
        <div class="post-body-img">
          <img :src="postData.image" alt="img" />
        </div>
        <p class="post-body-text" v-html="postData.content">
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import { onUnmounted } from '@vue/composition-api'
import router from "@/router";

import store from '@/store'
import storeModule from '@/views/Post/postStoreModule'

export default {
  name: "Post",
  data() {
    return {
      postData: {},
    };
  },
  setup() {
    const INVOICE_APP_STORE_MODULE_NAME = 'app-post-detail'

    // Register module
    if (!store.hasModule(INVOICE_APP_STORE_MODULE_NAME)) store.registerModule(INVOICE_APP_STORE_MODULE_NAME, storeModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(INVOICE_APP_STORE_MODULE_NAME)) store.unregisterModule(INVOICE_APP_STORE_MODULE_NAME)
    })
  },
  async created() {
    const slug = router.currentRoute.params.slug;

    this.$emit('loading', true)
    await store.dispatch('app-post-detail/fetchBlogPost', slug).then(response => {
      this.postData = response.data.data
      this.$emit('loading', false)
    }).finally(() => {
      this.$emit('loading', false)
    })
  },
  filters: {
    formatDate(value) {
      if (value) {
        const date = new Date(value)
        const options = {
          year: 'numeric',
          month: 'long',
          day: 'numeric',
          hour: 'numeric',
          minute: 'numeric',
          second: 'numeric',
          hour12: false,
        }
        return new Intl.DateTimeFormat('en-US', options).format(date)
      }
      return ''
    },
  },
};
</script>

<style lang="scss" scoped>
@import 'src/assets/scss/colors/colors';

.post {
  &-head {
    position: relative;
    font-family: "Calibri", sans-serif;
    font-style: normal;
    width: 100%;
    height: 380px;
    display: flex;
    align-items: center;
    justify-items: center;
    margin-bottom: 60px;
    img {
      width: 100%;
      border-radius: 0;
      height: 100%;
      object-fit: cover;
      position: relative;
    }
    &:after {
      content: "";
      position: absolute;
      left: 0;
      top: 0;
      height: 100%;
      width: 100%;
      background: rgba(255, 179, 0, 0.8);
    }
    &-title {
      position: absolute;
      font-size: 66px;
      font-weight: 700;
      color: white;
      text-align: center;
      width: 100%;
      z-index: 1;
    }
  }
  &-body {
    width: 1064px;
    margin: 0 auto;
    &-title {
      font-size: 36px;
      font-weight: normal;
      line-height: 44px;
      color: $secondary;
    }
    &-date {
      font-weight: normal;
      font-size: 20px;
      line-height: 24px;
      color: $secondary;
      margin-bottom: 30px;
    }
    &-img {
      width: 100%;
      margin-bottom: 70px;
      img {
        max-width: 100%;
        display: block;
        margin: 0 auto;
      }
    }
    &-text {
      font-weight: normal;
      font-size: 22px;
      line-height: 29px;
      text-align: justify;
      color: $secondary;
      margin-bottom: 100px;
    }
  }
  @media screen and (max-width: 767px) {
    .post {
      &-head-title {
        font-size: 48px;
      }
      &-body {
        width: 100%;
      }
    }
  }
}
</style>
